@mixin themify($themes) {
    @each $theme, $map in $themes {
        :global(.theme#{$theme}) & {
            $theme-map: () !global;

            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_") );

    $formats: ( otf: "opentype", ttf: "truetype" );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin breakpoint($point) {
    @if $point == xl {
        /* Large desktops and laptops */
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else if $point == lg {
        /* Landscape tablets and medium desktops */
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    }
    @else if $point == md {
        /* Portrait tablets and small desktops */
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }
    @else if $point == sm {
        /* Landscape phones and portrait tablets */
        @media (max-width: 767px) {
            @content;
        }
    }
    @else if $point == xs {
        /* Portrait phones and smaller */
        @media (max-width: 480px) {
            @content;
        }
    }
}