/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.bcIcon {
    width: 100%;
    height: 100%;
    display: inline-block;

    @include themify($themes) {
        color: var(--icon-secondary-color);
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
}

.iconFinderContainer {
    :global {
        .ant-list-pagination {
            width: 100%;
            float: left;
            padding: 24px 0;
            margin: 0;

            @include themify($themes) {
                border-top: 1px solid themed("modalHeaderBorderColor");
            }

            .ant-pagination-prev .ant-pagination-item-link,
            .ant-pagination-next .ant-pagination-item-link,
            .ant-pagination-item {
                @include themify($themes) {
                    background: themed("inputBackgroundColor");
                    color: themed("inputColor");
                    border-color: themed("modalHeaderBorderColor");
                }
            }

            .ant-pagination-item-active {
                border-color: var(--primary-color-6) !important;
            }

            .ant-pagination-item-ellipsis {
                @include themify($themes) {
                    color: themed("inputColor");
                }
            }
        }

        .ant-list-items {
            height: 300px;
            overflow-y: auto;
        }
        .ant-list-item {
            width: calc(100% / 10);
            // float: left;
            display: inline-block;
            border: none !important;
        }
        .ant-spin-container{
            height: 300px;
        }

    }

    .selected {
        @include themify($themes) {
            box-shadow: 0 0.25rem 0.125rem 0 themed("iconFinderItemHoverShadowColor");
            background-color: themed("iconFinderItemHoverBackgroundColor");
        }
    }

    .iconItem {
        margin-bottom: 15px;
        text-align: center;
        cursor: pointer;
        transition: all 200ms ease;
        user-select: none;
        width: 100%;
        padding-top: 6px;

        .bcIcon {
            margin-top: 10px;

            @include themify($themes) {
                color: themed("iconFinderItemIconColor");
            }
        }

        .iconText {
            width: 100%;
            display: inline-block;
            font-size: 12px;
            color: #777;
            margin-top: 5px;
            padding: 0 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &:hover {
            @include themify($themes) {
                box-shadow: 0 0.25rem 0.125rem 0 themed("iconFinderItemHoverShadowColor");
                background-color: themed("iconFinderItemHoverBackgroundColor");
            }
        }

        &:active {
            transform: scale(1.1);
        }
    }
}
